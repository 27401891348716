import './PageHeader.css';
export default function PageHeader({
    pageTitle, showBackButton = true, onBackButtonClick = () => null
}) {
    return <div className="AppPageHeader">
        <div className='btn_div no_select' style={{ cursor: 'pointer' }}>
            <button onClick={onBackButtonClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9998 19.92L8.47984 13.4C7.70984 12.63 7.70984 11.37 8.47984 10.6L14.9998 4.08" stroke="#057642" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
        <div className='ttl_div _black_20'> {pageTitle} </div>
    </div>
}